import React from 'react'
import { css } from '@emotion/core'
import { steamAnimation } from '../../utils/steamAnimation'

const AboutIllustration = ({ ...rest }) => {
  return (
    <svg
      {...rest}
      css={css`
        ${steamAnimation(['fume01', 'fume02', 'fume03'])}
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 372 202"
    >
      <g id="about_illustration">
        <path
          id="Rectangle_180"
          d="M372,183.724c0,-9.659 -7.841,-17.5 -17.5,-17.5l-337,0c-9.659,0 -17.5,7.841 -17.5,17.5c0,9.659 7.841,17.5 17.5,17.5l337,0c9.659,0 17.5,-7.841 17.5,-17.5Z"
          fill="#f0f0f0"
        />
        <g id="blue_coffee">
          <g id="Group_219">
            <path
              id="blue_coffee_mug"
              d="M245,125.224l-6,0c-6.583,0 -12,-5.417 -12,-12l0,-23c0,-1.097 0.903,-2 2,-2l26,0c1.097,0 2,0.903 2,2l0,6.51c0.955,-0.337 1.969,-0.513 3,-0.51c4.936,0.004 8.996,4.064 9,9c-0.005,4.935 -4.065,8.995 -9,9c-1.053,-0.001 -2.067,-0.187 -3.009,-0.527c-0.252,6.367 -5.566,11.527 -11.991,11.527Zm12,-16.015c0.838,0.635 1.878,1.013 3,1.015c2.743,0 5,-2.257 5,-5c0,-2.743 -2.257,-5 -5,-5c-1.122,0.002 -2.162,0.38 -3,1.015l0,7.97Z"
              fill="#7890e2"
            />
            <path
              id="line_of_light"
              d="M234.923,96.005l0,14.452"
              fill="none"
              fillRule="nonzero"
              stroke="#fff"
              strokeOpacity="0.5"
              strokeWidth="3px"
            />
          </g>
          <path
            id="fume03"
            d="M247.923,75.234l0,6.219"
            fill="none"
            fillRule="nonzero"
            stroke="#dbdbdb"
            strokeWidth="3px"
          />
          <path
            id="fume02"
            d="M241.923,67.005l0,14.452"
            fill="none"
            fillRule="nonzero"
            stroke="#dbdbdb"
            strokeWidth="3px"
          />
          <path
            id="fume01"
            d="M235.923,72.093l0,9.364"
            fill="none"
            fillRule="nonzero"
            stroke="#dbdbdb"
            strokeWidth="3px"
          />
        </g>
        <g id="Group_215">
          <path
            id="Rectangle_131"
            d="M322,129.224c0,-2.208 -1.792,-4 -4,-4l-263,0c-2.208,0 -4,1.792 -4,4c0,2.208 1.792,4 4,4l263,0c2.208,0 4,-1.792 4,-4Z"
            fill="#ddba7b"
          />
          <path
            id="Rectangle_216"
            d="M77.419,178.627c2.19,0 3.968,-1.778 3.968,-3.967l0,-41.843c0,-2.189 -1.778,-3.967 -3.968,-3.967c-2.189,0 -3.967,1.778 -3.967,3.967l0,41.843c0,2.189 1.778,3.967 3.967,3.967Z"
            fill="#ddba7b"
          />
          <path
            id="Rectangle_217"
            d="M295.377,178.627c2.189,0 3.967,-1.778 3.967,-3.967l0,-41.843c0,-2.189 -1.778,-3.967 -3.967,-3.967c-2.19,0 -3.968,1.778 -3.968,3.967l0,41.843c0,2.189 1.778,3.967 3.968,3.967Z"
            fill="#ddba7b"
          />
        </g>
        <g id="Group_222">
          <path
            id="Path_1039"
            d="M101.116,29.014c0,0 20.9,13.872 23.9,32.735c3,18.863 2.893,30.443 2.893,30.443c0,0 -1.7,-8.577 5.5,-26.113c7.2,-17.536 28.28,-17.833 28.28,-17.833"
            fill="none"
            fillRule="nonzero"
            stroke="#7caa60"
            strokeWidth="2px"
            strokeLinecap="butt"
          />
          <path
            id="Path_1040"
            d="M88.355,21.868c8.458,0 15.314,7.232 15.314,7.232c0,0 -6.856,7.232 -15.314,7.232c-8.458,0 -15.314,-7.232 -15.314,-7.232c0,0 6.856,-7.232 15.314,-7.232Z"
            fill="#82aa60"
            fillRule="nonzero"
          />
          <path
            id="Path_1041"
            d="M95.512,41.359c5.98,-5.98 15.942,-5.714 15.942,-5.714c0,0 0.266,9.961 -5.715,15.942c-5.98,5.981 -15.942,5.715 -15.942,5.715c0,0 -0.266,-9.962 5.715,-15.943Z"
            fill="#99c376"
            fillRule="nonzero"
          />
          <path
            id="Path_1042"
            d="M107.449,53.274c7.948,-2.893 16.864,1.558 16.864,1.558c0,0 -3.969,9.141 -11.917,12.034c-7.947,2.893 -16.864,-1.558 -16.864,-1.558c0,0 3.97,-9.141 11.917,-12.034Z"
            fill="#82aa60"
            fillRule="nonzero"
          />
          <path
            id="Path_1044"
            d="M171.723,45.821c8.13,2.331 12.727,11.173 12.727,11.173c0,0 -8.584,5.062 -16.714,2.731c-8.13,-2.332 -12.727,-11.173 -12.727,-11.173c0,0 8.583,-5.062 16.714,-2.731Z"
            fill="#82aa60"
            fillRule="nonzero"
          />
          <path
            id="Path_1515"
            d="M156.524,32.304c2.893,7.951 -1.56,16.869 -1.56,16.869c0,0 -9.143,-3.972 -12.036,-11.922c-2.892,-7.951 1.561,-16.869 1.561,-16.869c0,0 9.143,3.972 12.035,11.922Z"
            fill="#82aa60"
            fillRule="nonzero"
          />
          <path
            id="Path_1045"
            d="M154.005,55.881c8.13,2.331 12.727,11.173 12.727,11.173c0,0 -8.584,5.062 -16.714,2.731c-8.13,-2.332 -12.727,-11.173 -12.727,-11.173c0,0 8.583,-5.062 16.714,-2.731Z"
            fill="#99c376"
            fillRule="nonzero"
          />
          <path
            id="Path_1050"
            d="M109.327,21.108c3.304,-7.785 12.64,-11.27 12.64,-11.27c0,0 3.979,9.136 0.674,16.922c-3.305,7.786 -12.641,11.271 -12.641,11.271c0,0 -3.978,-9.137 -0.673,-16.923Z"
            fill="#82aa60"
            fillRule="nonzero"
          />
          <path
            id="Path_1051"
            d="M122.75,35.175c3.839,-7.536 13.396,-10.361 13.396,-10.361c0,0 3.331,9.392 -0.509,16.928c-3.84,7.536 -13.396,10.361 -13.396,10.361c0,0 -3.331,-9.392 0.509,-16.928Z"
            fill="#99c376"
            fillRule="nonzero"
          />
          <path
            id="Rectangle_208"
            d="M159,94.224c0,-9.934 -8.066,-18 -18,-18l-22,0c-9.934,0 -18,8.066 -18,18l0,13c0,9.934 8.066,18 18,18l22,0c9.934,0 18,-8.066 18,-18l0,-13Z"
            fill="#eaaf90"
          />
          <path
            id="Rectangle_209"
            d="M115.532,91.228c0,-1.213 -0.984,-2.197 -2.197,-2.197l-0.001,0c-1.213,0 -2.197,0.984 -2.197,2.197l0,18.483c0,1.213 0.984,2.197 2.197,2.197l0.001,0c1.213,0 2.197,-0.984 2.197,-2.197l0,-18.483Z"
            fill="#fff"
            fillOpacity="0.5"
          />
          <path
            id="Path_1053"
            d="M136.216,42.181c4.61,7.097 2.279,16.79 2.279,16.79c0,0 -9.805,-1.81 -14.414,-8.907c-4.61,-7.096 -2.279,-16.79 -2.279,-16.79c0,0 9.805,1.811 14.414,8.907Z"
            fill="#82aa60"
            fillRule="nonzero"
          />
          <path
            id="Path_1054"
            d="M158.93,32.156c5.211,-6.665 15.133,-7.613 15.133,-7.613c0,0 1.475,9.857 -3.735,16.522c-5.21,6.665 -15.132,7.613 -15.132,7.613c0,0 -1.476,-9.858 3.734,-16.522Z"
            fill="#99c376"
            fillRule="nonzero"
          />
          <path
            id="Path_1055"
            d="M93.145,17.594c-1.469,-8.33 4.463,-16.338 4.463,-16.338c0,0 8.312,5.497 9.781,13.826c1.469,8.33 -4.463,16.337 -4.463,16.337c0,0 -8.313,-5.496 -9.781,-13.825Z"
            fill="#99c376"
            fillRule="nonzero"
          />
          <path
            id="Rectangle_224"
            d="M159,80.724c0,-2.484 -2.016,-4.5 -4.5,-4.5l-49,0c-2.484,0 -4.5,2.016 -4.5,4.5c0,2.484 2.016,4.5 4.5,4.5l49,0c2.484,0 4.5,-2.016 4.5,-4.5Z"
            fill="#d09a7d"
          />
        </g>
      </g>
    </svg>
  )
}

export default AboutIllustration
