import React from 'react'
import { css } from '@emotion/core'
import Fade from 'react-reveal/Fade'
import { injectIntl } from 'gatsby-plugin-intl'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import {
  ServiceList,
  ServiceListItem,
  EmailLink,
  PhoneLink,
  Motto,
  SectionTitle,
  Line,
  Strong,
} from '../components/common'
import breakpoints from '../utils/breakpoints'
import AboutIllustration from '../components/illustrations/AboutIllustration'

const AboutPage = ({ intl }) => {
  return (
    <Layout lightFooter>
      <SEO
        title={intl.formatMessage({ id: 'about' })}
        lang={intl.locale}
        keywords={intl.formatMessage({ id: 'keywords' })}
        description={intl.formatMessage({ id: 'about_description' })}
      />
      <div
        css={theme => css`
          position: relative;
          font-size: ${theme.fontSize.small};
          width: 100%;
          background: ${theme.colors.fullWhite};
        `}
      >
        <div
          css={theme => css`
            background: ${theme.colors.white};
            color: ${theme.colors.accent};
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 24px;

            ${breakpoints.mobile} {
              height: unset;
              max-height: unset;
            }
          `}
        >
          <Motto>Why settle for less, when you could get the best?</Motto>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 768px;
            margin: 0 auto;
            padding: 24px;
          `}
        >
          <Line animated>
            <SectionTitle>
              {intl.formatMessage({ id: 'who_we_are' })}
            </SectionTitle>
            {intl.formatMessage({ id: 'we_are' })}
          </Line>
          <Line animated>
            <SectionTitle>
              {intl.formatMessage({ id: 'what_we_do' })}
            </SectionTitle>
            {intl.formatMessage({ id: 'we_do' })}
          </Line>
          <Line animated>
            <SectionTitle>
              {intl.formatMessage({ id: 'our_story_title' })}
            </SectionTitle>
            {intl.formatMessage({ id: 'our_story' })}
          </Line>
          <Line animated>
            <SectionTitle>
              {intl.formatMessage({ id: 'our_goal_title' })}
            </SectionTitle>
            {intl.formatMessage({ id: 'our_goal' })}
          </Line>
          <div
            css={theme => css`
              font-weight: bold;
              text-align: center;
              color: ${theme.colors.accent};
              font-size: ${theme.fontSize.medium};
              align-self: center;
            `}
          >
            <Fade bottom distance="24px" duration={750}>
              <p>{intl.formatMessage({ id: 'enough_of_us' })}</p>
            </Fade>
          </div>
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              width: ${55 / 1.52}%;
              align-self: center;
              margin: 64px 0;

              @media (max-width: 990px) {
                width: 55%;
            `}
          >
            <Fade bottom distance="24px" duration={750} cascade>
              <AboutIllustration />
            </Fade>
          </div>
          <div
            css={css`
              width: 100%;
              margin-bottom: 24px;
              text-align: center;
            `}
          >
            <Fade bottom distance="24px" duration={750}>
              {intl.formatMessage({ id: 'lets_talk_about_you' })}
            </Fade>
          </div>
          <Line
            css={css`
              padding-bottom: 24px;
            `}
          >
            <ServiceList dark>
              <Fade bottom distance="24px" duration={750}>
                <ServiceListItem
                  css={theme => css`
                    padding-bottom: 24px;
                    font-size: ${theme.fontSize.small};
                  `}
                  dark
                >
                  <Strong>
                    {intl.formatMessage({ id: 'modern_technology_bold' })}
                  </Strong>
                  {intl.formatMessage({ id: 'modern_technology_rest' })}
                </ServiceListItem>
              </Fade>
              <Fade bottom distance="24px" duration={750}>
                <ServiceListItem
                  css={theme => css`
                    padding-bottom: 24px;
                    font-size: ${theme.fontSize.small};
                  `}
                  dark
                >
                  <Strong>
                    {intl.formatMessage({ id: 'thorough_planning_bold' })}
                  </Strong>
                  {intl.formatMessage({ id: 'thorough_planning_rest' })}
                </ServiceListItem>
              </Fade>
              <Fade bottom distance="24px" duration={750}>
                <ServiceListItem
                  css={theme => css`
                    padding-bottom: 24px;
                    font-size: ${theme.fontSize.small};
                  `}
                  dark
                >
                  <Strong>
                    {intl.formatMessage({ id: 'wide_knowledge_bold' })}
                  </Strong>
                  {intl.formatMessage({ id: 'wide_knowledge_rest' })}
                </ServiceListItem>
              </Fade>
            </ServiceList>
          </Line>
          <Line
            css={css`
              text-align: center;
            `}
          >
            <Fade bottom distance="24px" duration={750}>
              {intl.formatMessage({ id: 'any_problem' })}
            </Fade>
          </Line>
          <div
            css={theme => css`
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              font-size: ${theme.fontSize.medium};
              align-self: center;
            `}
          >
            <Fade bottom distance="24px" duration={750}>
              <EmailLink
                css={theme => css`
                  color: ${theme.colors.accent};
                  margin: 8px 16px;
                `}
                data="hello@silivey.com"
              />
              <PhoneLink
                css={theme => css`
                  color: ${theme.colors.accent};
                  white-space: nowrap;
                  margin: 8px 16px;
                `}
                data="+36 (30) 903 2900"
              />
            </Fade>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(AboutPage)
